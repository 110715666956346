<template>
    <div>
        <img src="@/assets/logo.png" alt="logo corporativo geotecnia">
        <form @submit.prevent="token">
            <input type="text" v-model="user" placeholder="Usuario">
            <input type="password" v-model="password" placeholder="Contraseña">
            <input type="submit">
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import qs from 'qs'
export default {
    name: 'Login',
    data: () => {
        return {
            user: null,
            password: null
        }
    },
    computed:{
        ...mapState(['cache'])
    },
    methods:{
        ...mapActions(['api','auth','cargarlistas']),
        async token(){
            var data = qs.stringify({
                user: this.user,
                password: this.password,
                tipo:'login'
            });
            var config = {
                method: 'post',
                url: 'https://www.centrocatalan.synology.me/php2/back.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                    data : data
            };
            const a = this
            await axios(config)
            .then(function (response) {
                if(typeof response.data === 'object'){
                    alert(response.data.error)
                    a.user = null
                    a.password = null
                }else{
                    localStorage['token'] = response.data
                }
            })
            .catch(function (error) {
                console.log(error);
            });
            this.user = null
            this.password = null
            if(localStorage['token']){
                this.$router.push({name:'Home'})
            }
        }
    }
}
</script>

<style scoped>
input{
    display: block;
}
div{
    display: block;
}
form{
    margin-left: calc(50% - 100px);
    width: 200px;
    text-align: center;
}
img{
    width: 250px;
}
</style>